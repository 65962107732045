* { 
  box-sizing: border-box; /* 모든 요소에 border-box 적용 */
}

.full-layout {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 0;
  z-index: 1;
}

.splitter {
  align-self: center;
  width: 24px;
  border: none;
  border-left: 2px solid #cdcdcd;
  padding: 0;
  box-sizing: border-box;
  cursor: ew-resize;

  position: relative;
}

.splitter-button-layout {
  align-self: center; 
  margin: 0 0 10rem 0;
}

.splitter-button {
  display: block !important;
  position: absolute !important;
  z-index: 1 !important;
}

.global-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5rem 1.2rem;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gemini-gradient {
  background: linear-gradient(to right, rgb(75, 75, 252), rgb(250, 86, 86));
  background-clip: text; /* For broader compatibility */
  -webkit-background-clip: text; /* WebKit fallback */
  -webkit-text-fill-color: transparent; /* WebKit fallback */
  background-size: 200% 100%; /* Adjust based on your gradient size */
  animation: gradientAnimation 5s ease infinite; /* Adjust duration and timing function as needed */
}

/* 스크롤바 전체 */
::-webkit-scrollbar {
	width: 3px; /* 스크롤바 너비 */
  }
  
  /* 스크롤바 트랙 */
  ::-webkit-scrollbar-track {
	background-color: transparent !important;
  }
  
  /* 스크롤바 썸 (스크롤바 막대) */
  ::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2); /* 썸 배경색 */
	border-radius: 4px; /* 썸 모서리 둥글게 */
  }
  
  /* 스크롤바 썸 호버 시 */
  ::-webkit-scrollbar-thumb:hover {
	background-color: #555; /* 썸 호버 시 배경색 */
  }

  .container {
    max-width: 1500px;
    min-width: 900px;
    padding: 0 25px;
  }

