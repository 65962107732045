.editor-show{
    display: block;
}

.editor-hide{
    display: none;
}

.cursor-cross{
    cursor: crosshair;
}

.cursor-default{
    cursor: default;
}  

.cursor-move{
    cursor: move;
}