// .container {
//   width:750px;
//   margin: 20px auto;
//   padding: 20px;
//   // background: white;
//   box-shadow: 0 0 10px rgba(0,0,0,0.1);
//   border-radius: 8px;
// }
.field {
  padding-bottom: 20px;
}
.field:not(:first-child) {
  border-top: 1px solid #000; /* 검정색 선 추가 */
}
.field-row {
  display: flex;
  align-items: stretch; /* 변경된 부분: center에서 stretch로 변경 */
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  /*padding-bottom: 10px;*/
}
.title1 {
  font-weight: bold;
  width: 25%;
  text-align: center;
  color: #5a5a5a;
  border-right: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px; /* 패딩 추가 */
}
.content {
  flex-grow: 1;
  text-align: left;
  padding: 10px;
  display: flex;
  align-items: center; /* 컨텐츠를 중앙에 정렬 */
  width: 400px;
}

.field-title {
  text-align: left;
  font-size: 1.2em;
  margin-bottom: 10px;
}

