/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;

@import 'swiper/css';
@import 'swiper/css/effect-coverflow'; // 큐브 효과 CSS
@import 'swiper/css/effect-cards'; // 큐브 효과 CSS
@import 'swiper/css/effect-cube'; // 큐브 효과 CSS

@import './styles/layout.scss';
@import './styles/complain.scss';
@import './styles/material.scss';
@import './styles/editor.scss';

@font-face {
	font-family: 'NotoSans';
	font-style: normal;
	font-weight: 400;
	src: url(./assets/font/NotoSansKR-Regular.ttf) format('opentype');
}

@font-face {
	font-family: 'Pretendard-Regular';
	src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* fallback */



$baseColor : #227b4f;

$my-typography: mat.define-typography-config($font-family: 'Pretendard-Regular'
	);
@include mat.typography-hierarchy($my-typography);

// 앵귤러 머테리얼 3용 설정
$m3-light-theme: matx.define-theme((color: (primary: matx.$m3-azure-palette,
				tertiary: matx.$m3-blue-palette,
			),
			typography: (plain-family: 'Pretendard-Regular',
				brand-family: 'Pretendard-Regular'
			)));

// 앵귤러 머테리얼 pre-built themes용 설정
$my-theme: mat.define-light-theme((typography: mat.define-typography-config($font-family: 'Pretendard-Regular'
			),
		));
@include mat.all-component-themes($my-theme);

* {
	box-sizing: border-box;
}


html,
body {
	width: 100%;
	height: 100%;
	font-family: 'Pretendard-Regular', "NotoSans" !important;
	padding: 0;
	margin: 0;
	// font-family: "NotoSans" !important;
	// @include mat.all-component-themes($m3-light-theme);
}


body {
	color: #222;
	font-family: "NotoSans";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.4;
	word-break: keep-all;
	word-wrap: break-word;
	background-color: white;
}

dl,
ul,
ol,
li {
	list-style: none;
}

.pointer {
	cursor: pointer;
}

button,
input,
textarea,
select {
	font-family: "NotoSans";
	font-size: 1rem;
	border: none;
	cursor: pointer;
	display: inline-block;
}

button {
	background-color: transparent;
}

fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
img {
	border: 0;
}

pre {
	font-family: "NotoSans";

}

.chat-box-answer {
	display: inline-block;
	padding: 15px 20px;
	background-color: #c6e79e35;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	margin: 4px 0 10px;
}

.chat-box-question {
	display: inline-block;
	color: black;
	padding: 15px 20px;
	background-color: #3584ce46;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	margin: 10px 0;
}

.margin0auto {
	margin: 0 auto;
}

.w100 {
	width: 100%;
}

.w90 {
	width: 90%;
}

.w80 {
	width: 80%;
}

.w30 {
	width: 30%;
}

.w65 {
	width: 65%;
}

.w5 {
	width: 5%;
}

.h100 {
	height: 100%;
}

.h60 {
	height: 60px;
}

.p10 {
	padding: 10px;
}

.p30 {
	padding: 30px;
}

.pt-5 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-70 {
	padding-top: 70px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-30 {
	padding-left: 35px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pr-25 {
	padding-right: 25px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-40 {
	margin-top: 40px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-45 {
	margin-bottom: 45px;
}

.mr-5 {
	margin-right: 5px;
}

.l-10 {
	left: 10px;
}

.b-10 {
	bottom: 10px;
}

.t15 {
	top: 15px;
}

.right0 {
	margin: 0 0 0 auto;
}

.r15 {
	right: 15px;
}

.line1 {
	line-height: 1%;
}

.radius-15 {
	border-radius: 15px;
}

.radius-25 {
	border-radius: 25px;
}

.radius-30 {
	border-radius: 30px;
}

.color-brown-dark {
	color: #37342E;
}

.color-brown-light {
	color: #A09F9C;
}

.color-point {
	color: #54ABA1;
}

.color-fff {
	color: #fff;
}

.color-000 {
	color: #000;
}

.color-666 {
	color: #666;
}

.color-999 {
	color: #999;
}

.back-primary {
	background-color: #5E5E5E;
}

.back-black {
	background-color: #000;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.flex {
	display: flex;
}

.flex-col {
	flex-direction: column;
}

.flex-v-center {
	align-items: center;
}

.gap20 {
	gap: 20px;
}

.title {
	color: #000;
	font-size: 2rem !important;
	font-weight: 700 !important;
	letter-spacing: -1px !important;
	line-height: 180% !important;
}

.text-ellipsis {
	overflow: hidden;
	/* 넘치는 내용 숨기기 */
	white-space: nowrap;
	/* 텍스트를 한 줄로 표시 */
	text-overflow: ellipsis;
	/* 말줄임표 표시 */
	display: block;
}