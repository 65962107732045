@import './color.scss';

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./../assets/font/material.woff2) format('woff2');
}


.glass-effect {
    background: rgba(255, 255, 255, 0.5) !important;
    /* 배경 투명도 */
    backdrop-filter: blur(10px);
    /* 흐림 효과 */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    /* 그림자 */
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* 테두리 */
    color: rgb(45, 45, 45) !important;
}

/* component.css */
mat-card {
    @extend .glass-effect;
    /* 전역 스타일 상속 */
}

mat-toolbar {
    @extend .glass-effect;
    /* 전역 스타일 상속 */
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;

}

.mat-mdc-paginator {
    @extend .glass-effect;
    /* 전역 스타일 상속 */

}


//TODO elctron 적용 시 폰트 확인 필요

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

//raised-button
.mat-mdc-raised-button {
    @extend .glass-effect;
    /* 전역 스타일 상속 */
}

//outlined-button
.mat-mdc-outlined-button:not(:disabled) {
    color: #{$baseColor} !important;
}

//버튼 hover 시
.mdc-button:hover {
    @extend .glass-effect;
    /* 전역 스타일 상속 */
}

//mat-button
.mat-mdc-button:not(:disabled) {
    @extend .glass-effect;
    /* 전역 스타일 상속 */

    color: #{$baseColor} !important;
}

.mat-mdc-dialog-panel {
    // @extend .glass-effect;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.692);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.mat-mdc-card {
    background-color: rgba(0, 0, 0, 0) !important;
}

//탭
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
    @extend .glass-effect;
    /* 전역 스타일 상속 */

    --mdc-tab-indicator-active-indicator-color: #{$baseColor};
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: black;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: #{$baseColor};
    --mat-tab-header-active-ripple-color: #{$baseColor};
    --mat-tab-header-inactive-ripple-color: #{$baseColor};
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: #{$baseColor};
    --mat-tab-header-active-hover-label-text-color: #{$baseColor};
    --mat-tab-header-active-focus-indicator-color: #{$baseColor};
    --mat-tab-header-active-hover-indicator-color: #{$baseColor};
}

//칩스
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
    @extend .glass-effect;
    /* 전역 스타일 상속 */

    --mdc-chip-elevated-selected-container-color: #{$baseColor};
    --mdc-chip-elevated-container-color: #{$baseColor};
}

//토글
html .mat-mdc-slide-toggle.mat-accent {
    /* 전역 스타일 상속 */

    --mdc-switch-selected-focus-state-layer-color:  #3584ce;
    --mdc-switch-selected-handle-color:  #24619a;
    --mdc-switch-selected-hover-state-layer-color:  #3584ce;
    --mdc-switch-selected-pressed-state-layer-color:  #3584ce;
    --mdc-switch-selected-focus-handle-color: #{$baseColor};
    --mdc-switch-selected-hover-handle-color: #{$baseColor};
    --mdc-switch-selected-pressed-handle-color: #{$baseColor};

    --mdc-switch-selected-focus-track-color: #3584ce;
    --mdc-switch-selected-hover-track-color: #3584ce;
    --mdc-switch-selected-pressed-track-color: #3584ce;
    --mdc-switch-selected-track-color: #3584ce;
}

//스테퍼
.mat-step-icon-selected {
    @extend .glass-effect;
    // background-color: #{$baseColor} !important;
}

.mat-step-icon-state-edit {
    @extend .glass-effect;
    // background-color: #{$baseColor} !important;
}

.mat-mdc-dialog-surface {
    background-color: transparent !important;
}

.mat-mdc-dialog-container {
    background-color: transparent !important;
}

.mat-drawer {
    background-color: rgba(255, 255, 255, 0.3) !important;
}


//셀렉트
.mat-primary {
    --mat-full-pseudo-checkbox-selected-icon-color: #{$baseColor};
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #{$baseColor};
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: #{$baseColor} !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: #{$baseColor} !important;

}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: #{$baseColor} !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: #{$baseColor} !important;
}

//리플 색상 변경
.mat-ripple-element {
    //background-color:#{$baseColor-light} !important;
}

//인풋
.mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: 28px !important;
}

/* Change the border color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #{$baseColor} !important;
}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: #{$baseColor} !important;
}

/* Change the border hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: #{$baseColor} !important;
}

.mat-drawer-container {
    background-color: rgba(0, 0, 0, 0) !important;
}


//table
.mat-table {
    border-radius: 8px !important;
    overflow: hidden !important;
}

.mat-elevation-z8 {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.mat-mdc-table {
    background-color: rgba(0, 0, 0, 0) !important;
}

.custom-tooltip {
    white-space: pre-line;
    /* 줄바꿈 허용 */
    max-width: 200px;
    /* 툴팁 최대 너비 설정 */
}

// .usage-guide.mat-mdc-dialog-panel{
//     width: 1800px !important;
// }

.mat-drawer-content {
	overflow: hidden !important;
}

.mat-mdc-menu-panel {
    background: rgba(255, 255, 255, 0.25) !important; 
    backdrop-filter: blur(10px) !important; 
    border-radius: 10px !important; 
    border: 1px solid rgba(255, 255, 255, 0.5) !important; 
}

.mat-bottom-sheet-container  {
    background-color: transparent !important;
    backdrop-filter: blur(10px); /* Blur effect for the frosted glass look */
    border-radius: 10px; /* Optional: rounded corners for a polished look */
    border: 1px solid rgba(255, 255, 255, 0.25); /* Optional: subtle border for light shine effect */
    padding: 20px; /* Adjust padding to your preference */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for depth, adjust as needed */
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
    backdrop-filter: blur(10px); /* Blur effect for the frosted glass look */

}

.cdk-overlay-backdrop {
  background-color: rgba(109, 109, 109, 0.1) !important;
  //backdrop-filter: blur(1px); /* Blur effect for the frosted glass look */

}

div.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
    background-color: transparent !important;
    backdrop-filter: blur(10px); /* Blur effect for the frosted glass look */
}

